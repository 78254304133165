import React from "react";

import Layout from "../components/layout/Layout";
import { DiscordLink } from "../components/discord/DiscordLink"

import styles from "./pages.module.scss";

const Contact = (props) => {
    return (
        <Layout
            location={props.location}
            title={"Get in touch"}
            type={"contact"}
        >
            <div className={styles.body}>
                <h1>Get in touch</h1>
                <h2>There was a form here, but it's gone now.</h2>
                <p>But with a bit of luck, it'll be back very soon!</p>
                <p>Until then, if you'd like to get in touch, drop OZARIN (<a href="https://twitter.com/ozarin_tweets" target="_blank" rel="noreferrer">@ozarin_tweets</a>) or Bryan (<a href="https://twitter.com/bryoz" target="_blank" rel="noreferrer">@bryoz</a>) a message on Twitter  or join the conversation on <DiscordLink>the OZARIN Discord server</DiscordLink></p>
            </div>
        </Layout>
    )
}

export default Contact;